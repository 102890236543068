import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Provider } from 'react-redux';

import Main from './Main';
import Routes from './routes';
import { store } from './store';
import './styles.less';

const App: React.FC = () => {
  useEffect(() => {
    if (process.env.REACT_APP_MEASUREMENT_ID) {
      ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID);
    }

    if (process.env.REACT_APP_HUBSPOT_SCRIPT_ID && process.env.REACT_APP_HUBSPOT_SCRIPT_URL) {
      const script = document.createElement('script');

      script.type = 'text/javascript';
      script.id = process.env.REACT_APP_HUBSPOT_SCRIPT_ID || '';
      script.src = process.env.REACT_APP_HUBSPOT_SCRIPT_URL || '';
      script.async = true;

      document.body.appendChild(script);
    }
  }, []);

  return (
    <Provider store={store}>
      <ConfigProvider locale={enUS}>
        <Main>
          <Routes />
        </Main>
      </ConfigProvider>
    </Provider>
  );
};

export default App;
