import { Button, Form, Input, Typography } from 'antd';
import { ArgsProps } from 'antd/es/notification';
import qs from 'qs';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';

import { Action } from '../../../../store';
import { moduleName as authModuleName, changeStateAction, resetPasswordAction } from '../../../../store/ducks/auth';
import { JsonResult } from '../../../../store/ducks/common';
import { openNotificationAction } from '../../../../store/ducks/local';
import { RootState } from '../../../../store/reducers';
import { password as passwordRule } from '../../../../utils/inputRules';
import styles from '../index.module.less';

interface IResetPasswordData {
  password: string;
  passwordConfirm: string;
}

interface IResetPassword {
  loading: boolean;
  isPasswordChanged: boolean;
  resetPassword: (password: string, token: string) => Action;
  changeState: (payload: JsonResult) => Action;
  openNotification: (params: ArgsProps) => Action;
}

const ResetPassword: React.FC<IResetPassword> = ({
  loading,
  isPasswordChanged,
  resetPassword,
  changeState,
  openNotification,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isPasswordChanged) return;

    changeState({ isPasswordChanged: false });
  }, []);

  useEffect(() => {
    if (!isPasswordChanged) return;
    openNotification({ type: 'success', message: 'Password was changed successfully!' });
    navigate('/');
  }, [isPasswordChanged]);

  const handleSubmit = (values: IResetPasswordData) => {
    const { token = '' } = qs.parse(location.search.replace('?', ''));
    const { password } = values;

    resetPassword(password, token as string);
  };

  return (
    <div className={styles.container}>
      <Typography.Title className={styles.title}>Reset Password</Typography.Title>
      <Form layout="vertical" className={styles.form} onFinish={handleSubmit} labelWrap>
        <Form.Item name="password" label="New Password" rules={passwordRule}>
          <Input.Password size="large" />
        </Form.Item>
        <Form.Item
          name="passwordConfirm"
          label="New Password Confirm"
          dependencies={['password']}
          rules={[
            ...passwordRule,
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error('The new password that you entered do not mach!'));
              },
            }),
          ]}
        >
          <Input.Password size="large" />
        </Form.Item>
        <Form.Item noStyle>
          <Button
            loading={loading}
            disabled={loading}
            type="primary"
            size="large"
            htmlType="submit"
            className={styles.authButton}
          >
            Reset Password
          </Button>
        </Form.Item>
      </Form>
      <div className={styles.footer}>
        <Link className={styles.link} to="/auth/sign-in">
          Return to Sign In
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state[authModuleName].loading,
  isPasswordChanged: state[authModuleName].isPasswordChanged,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetPassword: (password: string, token: string) => dispatch(resetPasswordAction(password, token)),
  changeState: (payload: JsonResult) => dispatch(changeStateAction(payload)),
  openNotification: (params: ArgsProps) => dispatch(openNotificationAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
