import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, Form, Input, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { ArgsProps } from 'antd/es/notification';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';

import { Action } from '../../../../../../store';
import { moduleName as charactersModuleName, updateCharacterAction } from '../../../../../../store/ducks/characters';
import { openNotificationAction } from '../../../../../../store/ducks/local';
import { RootState } from '../../../../../../store/reducers';
import { ICharacterDetails, ICharacterUpdate } from '../../../../../../types/entries';
import { copyTextToClipboard } from '../../../../../../utils/text';

interface IPromptTab {
  character: ICharacterDetails | null;
  updateCharacter: (body: ICharacterUpdate) => Action;
  openNotification: (params: ArgsProps) => Action;
}

const PromptTab: React.FC<IPromptTab> = ({ character, updateCharacter, openNotification }) => {
  const [form] = useForm();

  useEffect(() => {
    if (!character) return;

    const { uuid: assistant_uuid, first_message, system_prompt, activation_id } = character;

    form.setFieldsValue({ assistant_uuid, first_message, system_prompt, activation_id });
  }, [character]);

  const handleCopyActivationId = () => {
    if (!character?.activation_id) return;

    copyTextToClipboard(character.activation_id).then(() => {
      openNotification({ message: 'The Activation ID is copied to the clipboard' });
    });
  };

  return (
    <Form layout="vertical" onFinish={updateCharacter} form={form}>
      <Form.Item name="assistant_uuid" noStyle />
      <Form.Item name="first_message" label="First Message">
        <Input size="large" />
      </Form.Item>
      <Form.Item>
        <Form.Item name="system_prompt" label="System Prompt">
          <Input.TextArea size="large" rows={6} />
        </Form.Item>
        <Typography className="fs-14 color-primary">
          Want to get the best out of your character?{' '}
          <Link to="https://onboarding.neural-voice.ai/prompt-guide" target="_blank">
            Use our prompt guide
          </Link>
        </Typography>
      </Form.Item>
      <Form.Item>
        <Form.Item name="activation_id" label="Activation ID">
          <Input
            size="large"
            disabled
            className="activation-id-button"
            suffix={
              <Button type="text" style={{ paddingInline: '0.75rem' }} onClick={handleCopyActivationId}>
                <ContentCopyIcon className="fs-16 color-primary" />
              </Button>
            }
          />
        </Form.Item>
        <Typography className="fs-14 color-primary">
          Please add this ID button to activate neural voice{' '}
          <Link to="https://onboarding.neural-voice.ai/website-integration" target="_blank">
            How to Integrate Neural Voice on Your Website
          </Link>
        </Typography>
      </Form.Item>

      <Form.Item className="flex-row flex-justify-end m-0">
        <Button htmlType="submit">Save Character</Button>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = (state: RootState) => ({
  character: state[charactersModuleName].character,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateCharacter: (body: ICharacterUpdate) => dispatch(updateCharacterAction(body)),
  openNotification: (params: ArgsProps) => dispatch(openNotificationAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PromptTab);
