import { SorterResult } from 'antd/es/table/interface';
import moment from 'moment-timezone';

import { JsonResult } from '../types';
import { IRecord } from '../types/entries';

export function capitalizeFirstLetter(str: string): string {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
}

/**
 * Returns a JS object representation of a Javascript Web Token from its common encoded
 * string form.
 *
 * @template T the expected shape of the parsed token
 * @param {string} token a Javascript Web Token in base64 encoded, `.` separated form
 * @returns {(T | undefined)} an object-representation of the token
 * or undefined if parsing failed
 */
export function getParsedJwt<T = JsonResult>(token?: string): T | undefined {
  try {
    return token ? JSON.parse(atob(token.split('.')[1])) : undefined;
  } catch {
    return undefined;
  }
}

export const getSorterParams = (sorter: SorterResult<IRecord> | SorterResult<IRecord>[]): { [key: string]: string } => {
  const newParams: { [key: string]: string } = {};
  let sorterObj = sorter;

  if (Array.isArray(sorter)) {
    [sorterObj] = sorter;
  } else {
    sorterObj = sorter;
  }

  if (sorterObj.column?.key) {
    newParams.sort = `${sorterObj.column.key}`;
  }

  if (sorterObj.order) {
    newParams.order = sorterObj.order === 'ascend' ? 'ASC' : 'DESC';
  }

  return newParams;
};

export const queryFilterParams = (queryParams: Record<string, string>): Record<string, string> => {
  const params = Object.entries(queryParams);
  const newParams: { [key: string]: string } = {};

  params.forEach(([key, value]) => {
    if (value) {
      newParams[key] = value.toString();
    }
  });

  return newParams;
};

export const getBase64 = (file: Blob, isPure?: boolean): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      const result = isPure
        ? (reader.result as string).replace(/data:\w+\/\w+;base64,/, '')
        : (reader.result as string);

      resolve(result);
    };
    reader.onerror = (error) => reject(error);
  });

export const imageUrlToBase64 = async (url: string) => {
  const data = await fetch(url);
  const blob = await data.blob();

  const base64 = await getBase64(blob, true);

  return base64;
};

export const generateRandomString = (): string => Math.random().toString(36).substring(2, 8);

export const getTimeZoneName = (): string => moment.tz.guess(true);
