import { ClearOutlined, PauseRounded, PlayArrowRounded } from '@mui/icons-material';
import { Button, Form, FormInstance, Image, Input, Select, Typography, Upload, UploadFile, UploadProps } from 'antd';
import ImgCrop from 'antd-img-crop';
import { ArgsProps } from 'antd/es/notification';
import Compress from 'compress.js';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { imageUploadLimitMb } from '../../../../../config';
import { Action } from '../../../../../store';
import { moduleName as authModuleName } from '../../../../../store/ducks/auth';
import { moduleName as charactersModuleName, getVoicesAction } from '../../../../../store/ducks/characters';
import { openNotificationAction } from '../../../../../store/ducks/local';
import { RootState } from '../../../../../store/reducers';
import { JsonResult } from '../../../../../types';
import { IUser, IVoice } from '../../../../../types/entries';
import { getBase64 } from '../../../../../utils';
import { characterName, characterVoice } from '../../../../../utils/inputRules';

const compressor = new Compress();

interface ICreateCharacterForm {
  form: FormInstance;
  user: IUser | null;
  voices: IVoice[];
  openNotification: (params: ArgsProps) => Action;
  getVoices: () => Action;
}

const CreateCharacterForm: React.FC<ICreateCharacterForm> = ({ form, user, voices, openNotification, getVoices }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [audioSource, setAudioSource] = useState<string>('');
  const audio = useMemo(() => new Audio(audioSource), [audioSource]);

  useEffect(() => {
    getVoices();
  }, []);

  useEffect(() => {
    if (!form || !user) return;

    form.setFieldValue('organisation_uuid', user.organisation?.uuid);
  }, [form, user]);

  const beforeUpload = (file: Blob) => {
    const isJpgOrPng = file.type === 'image/png';

    if (!isJpgOrPng) {
      setError('You can only upload PNG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < imageUploadLimitMb;

    if (!isLt2M) {
      setError(`Image must smaller than ${imageUploadLimitMb}MB!`);
    }

    return isJpgOrPng && isLt2M;
  };

  useEffect(() => {
    if (!error) return;

    openNotification({ type: 'error', message: error });
  }, [error]);

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handlePreview = async (file: UploadFile) => {
    let previewUrl = file.url || file.preview;

    if (!previewUrl) {
      previewUrl = await getBase64(file.originFileObj as Blob);
    }

    setPreviewImage(previewUrl);
    setPreviewOpen(true);
  };

  const customRequest = async (options: JsonResult) => {
    const file = await compressor.compress(options.file, {
      size: 1,
      quality: 0.95,
      maxWidth: 500,
      maxHeight: 500,
    });

    const imageData = await getBase64(file as unknown as Blob, true);

    form.setFieldValue('image_data', imageData);
    options.onSuccess();
  };

  audio.onended = () => {
    setAudioSource('');
  };

  useEffect(() => {
    if (!audioSource) return;

    audio.play();
  }, [audio]);

  const toggleAudio = (event: React.MouseEvent<HTMLElement, MouseEvent>, url: string) => {
    event.stopPropagation();

    audio.pause();
    if (audioSource === url) {
      setAudioSource('');
    } else {
      setAudioSource(url);
    }
  };

  const pauseSoundOnDropdownClose = (open: boolean) => {
    if (!open) {
      audio.pause();
      setAudioSource('');
    }
  };

  return (
    <Form layout="vertical" form={form}>
      <Form.Item name="organisation_uuid" noStyle />
      <Form.Item name="image_data">
        <ImgCrop rotationSlider cropShape="round" fillColor="transparent">
          <Upload
            accept="image/png, image/jpeg"
            beforeUpload={beforeUpload}
            listType="picture-card"
            fileList={fileList}
            customRequest={customRequest}
            onChange={onChange}
            onPreview={handlePreview}
            maxCount={1}
          >
            {fileList.length < 1 && '+ Upload'}
          </Upload>
        </ImgCrop>
      </Form.Item>
      <Form.Item name="name" label="Name" rules={characterName}>
        <Input size="large" />
      </Form.Item>
      <Form.Item name="elevenlabs_voice_id" label="Voice" rules={characterVoice}>
        <Select
          showSearch
          allowClear
          showArrow={false}
          size="large"
          onDropdownVisibleChange={pauseSoundOnDropdownClose}
          optionLabelProp="label"
          optionFilterProp="children"
          filterOption={(input, option) =>
            ((option?.label ?? '') as string).toLowerCase().includes(input.toLowerCase())
          }
          clearIcon={<ClearOutlined className="fs-16 color-primary" />}
        >
          {voices.map((voice) => {
            const { id, name, gender, age, accent } = voice;
            const source = `/voices/${id}.mp3`;

            return (
              <Select.Option key={voice.id} value={voice.id} label={`${name}-${gender}-${age}-${accent}`}>
                <div className="flex-row flex-align-center gap-16">
                  <Typography.Text className="color-primary fs-12 fw-400 ws-nowrap lh-1" style={{ width: 50 }}>
                    {name}
                  </Typography.Text>
                  <Typography.Text className="color-primary fs-12 fw-400 ws-nowrap lh-1" style={{ width: 50 }}>
                    {gender}
                  </Typography.Text>
                  <Typography.Text className="color-primary fs-12 fw-400 ws-nowrap lh-1 flex-1 align-center">
                    {age}
                  </Typography.Text>
                  <Typography.Text className="color-primary fs-12 fw-400 ws-nowrap lh-1 flex-1 align-center">
                    {accent}
                  </Typography.Text>
                  <Button
                    className="color-primary"
                    type="text"
                    icon={audioSource === source ? <PauseRounded /> : <PlayArrowRounded />}
                    onClick={(event) => toggleAudio(event, source)}
                  />
                </div>
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      {previewImage && (
        <Image
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible: boolean) => setPreviewOpen(visible),
          }}
          src={previewImage}
        />
      )}
    </Form>
  );
};

const mapStateToProps = (state: RootState) => ({
  voices: state[charactersModuleName].voices,
  user: state[authModuleName].user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openNotification: (params: ArgsProps) => dispatch(openNotificationAction(params)),
  getVoices: () => dispatch(getVoicesAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCharacterForm);
