import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Typography } from 'antd';
import React, { PropsWithChildren, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';

import { Action } from '../../../store';
import { moduleName as authModuleName, signOut as signOutAction } from '../../../store/ducks/auth';
import { RootState } from '../../../store/reducers';
import { IUser } from '../../../types/entries';
import styles from './index.module.less';

interface IAuthLayout extends PropsWithChildren {
  withHeader?: boolean;
  authorized: boolean;
  isVerificationInProgress: boolean;
  user: IUser | null;
  signOut: () => Action;
}

const Auth: React.FC<IAuthLayout> = ({ withHeader, authorized, isVerificationInProgress, user, signOut, children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/auth/forgot-password' || location.pathname === '/auth/reset-password') {
      return;
    }

    if (!user && !authorized && location.pathname !== '/auth/sign-in' && location.pathname !== '/auth/sign-up') {
      navigate('/auth/sign-in');

      return;
    }

    if (user && !user.is_verified) {
      navigate('/auth/verify-email');

      return;
    }

    if (user && user.is_verified && isVerificationInProgress) {
      navigate('/auth/verification/success');

      return;
    }

    if (authorized) {
      navigate('/');
    }
  }, [authorized, user, isVerificationInProgress]);

  return (
    <div className={styles.layout}>
      <div className={styles.container}>
        {user && withHeader && (
          <div className={styles.header}>
            <div className="flex-column gap-4">
              <Typography.Text className={styles.username}>{`${user.first_name} ${user.last_name}`}</Typography.Text>
              <Button type="text" className={styles.logout} onClick={signOut}>
                Log Out
              </Button>
            </div>
            <Avatar size={60} icon={<UserOutlined />} src={user.avatar} />
          </div>
        )}
        <div className={styles.contentWrapper}>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

Auth.defaultProps = {
  withHeader: false,
};

const mapStateToProps = (state: RootState) => ({
  authorized: state[authModuleName].authorized,
  isVerificationInProgress: state[authModuleName].isVerificationInProgress,
  user: state[authModuleName].user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  signOut: () => dispatch(signOutAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
